@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-slate-100;
  @apply text-gray-900;

  @apply dark:bg-black;
  @apply dark:text-white;
}

.icon .social-svg {
  @apply hover:opacity-50;
  @apply hover:transition-all;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
